"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEmailTemplate = void 0;
const client_1 = require("@apollo/client");
const email_template_graphql_1 = require("./email-template.graphql");
const AccountContext_1 = require("contexts/AccountContext");
const useEmailTemplate = (slug) => {
    const { account } = (0, AccountContext_1.useAccount)();
    const getEmailTemplate = (0, client_1.useQuery)(email_template_graphql_1.GET_EMAIL_TEMPLATE, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id, notificationTypeSlug: slug },
        skip: !(account === null || account === void 0 ? void 0 : account.id),
        fetchPolicy: 'cache-and-network'
    });
    const template = getEmailTemplate;
    const { error, loading, data, refetch } = template;
    const emailTemplate = (data === null || data === void 0 ? void 0 : data.transactionalEmailTemplate) || null;
    return {
        error,
        loading,
        data: emailTemplate,
        refetch
    };
};
exports.useEmailTemplate = useEmailTemplate;
