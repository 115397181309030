"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentMethodCopy = void 0;
exports.paymentMethodCopy = {
    header: 'Add a Payment Method',
    cta: 'Add Payment Method',
    heroHeader: 'New in Malomo: Tracking Page Creator',
    heroSubHeader: 'With just two clicks, you can create a new order tracking page that seamlessly integrates with your Shopify theme.',
    linkPreface: '',
    linkPrompt: '',
    linkHref: '',
    errorTitle: 'Payment Method Error',
    resolutionCta: 'Skip for now',
    resolutionText: 'Please check your payment details and try again.',
    resolutionUrl: '/orders'
};
